import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  > div + div {
    margin-left: 40px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Flourish Customer Story - Rotaready"
          description="Find out how the integration between Rotaready and PayCaptain helped Flourish improve payroll efficiency and ensure employees are always paid accurately and on time."
          url="customer-stories/hospitality-flourish"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Flourish"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Flourish logo"
                  />

                  <GatsbyImage
                    image={data.logoPayCaptain.childImageSharp.gatsbyImageData}
                    alt="PayCaptain logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Find out how the integration between Rotaready and PayCaptain helped Flourish improve payroll efficiency and ensure employees are always paid accurately and on time." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="1" />
              <StatSubtitle text="site" />
            </StatItem>

            <StatItem>
              <StatHeadline text="62" />
              <StatSubtitle text="employees" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="Flourish" />
              </Content>

              <Content>
                <TextBlock text="Flourish started its journey in 2020 when founder, Paul Hargreaves and Paul Castle, teamed up with the owner of Glenavon Farm to transform the farms' redundant dairy buildings, into what soon became Flourish - a unique and exciting place for the local people and passers-by to stop, shop, eat and enjoy." />
              </Content>

              <Content>
                <TextBlock text="From the moment they opened, the team behind the Bristol based business wanted to make sure they were operating efficiently, and that their employees had everything they needed to make their working lives easier. So before they opened their doors to customers, the team set about finding software solutions that would enable them to do just that." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Flourish foodhall and pizzas"
              />
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingRota.childImageSharp.gatsbyImageData}
                alt="Simple, yet smart, staff scheduling"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Flourish & Rotaready" />
              </Content>

              <Content>
                <TextBlock text="First on the agenda for Flourish was a workforce management solution that would help the management team efficiently schedule and share staff rotas, and accurately monitor and control labour costs. They also wanted a tool that would enable employees to easily clock in and out and request holiday. Other key requirements included high usability of the chosen solution and excellent customer support." />
              </Content>

              <Section>
                <PopQuote text="After reviewing a number of solutions, Flourish decided that Rotaready was the perfect fit for the business." />
              </Section>

              <Content>
                <Blockquote
                  text="Unlike other systems we looked at, Rotaready allows you to do everything in just a couple of clicks, and that's exactly what we needed. A solution that was easy for our team to use."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>

              <Content>
                <Blockquote
                  text="Our Customer Success Manager is fantastic - that was a real clincher for us when deciding to go with Rotaready!"
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline3.childImageSharp.gatsbyImageData}
                alt="Flourish team"
              />
            </Section>

            <Section>
              <Content>
                <TextBlock text="Flourish have been making excellent use of Rotaready since implementing it in May 2021 and are using the majority of its features. Some particular favourites are how the Rota Editor enables them to share staff rotas well in advance. The visibility and insight they gain from Rotaready's Cost Control and how this helps them schedule perfectly to meet demand week on week. And how messaging has helped improve employee communication. They also love the assurance they now have that their team is always paid accurately for the hours they've worked, thanks to Rotaready's time and attendance functionality." />
              </Content>

              <Content>
                <Blockquote
                  text="We're a business that's all about work/life balance and our people are the most important thing. The fact Rotaready has enabled us to give our team plenty of notice of when they're working next and accurately track the number of hours they're doing, is of huge benefit to us."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Barista preparing a latte coffee"
              />
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingPayroll.childImageSharp.gatsbyImageData}
                alt="A tech savvy payroll partner"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Flourish & PayCaptain" />
              </Content>

              <Content>
                <TextBlock text="Alongside implementing Rotaready, Flourish were also looking to implement a payroll solution. The chosen solution had to be one that would enable them to process payroll easily and accurately and give employees visibility over their pay." />
              </Content>

              <Content>
                <TextBlock text="Support was also an important factor for the team at Flourish..." />
              </Content>

              <Content>
                <Blockquote
                  text="I was apprehensive about taking on a new system in an area that hadn't been my focus previously, but the team at PayCaptain held my hand through the implementation process and continue to support us extremely well to this day. As is the case with Rotaready - the team and the support they give us really makes all the difference!"
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Since implementing PayCaptain the team at Flourish have seen numerous benefits. Managers are loving the ease and accuracy of processing payroll and employees are enjoying the control they now have over their pay. Thanks to PayCaptain's feature rich mobile app employees have the option to easily split payments, access wages in advance and pre-plan savings." />
              </Content>

              <Content>
                <Blockquote
                  text="We're in a world where technology is our best friend. PayCaptain's mobile app makes payroll ‘modern'; eliminating paper payslips and giving employees visibility and control over their wages from the palm of their hand."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.headingIntegration.childImageSharp.gatsbyImageData}
                alt="A seamless integration"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Flourish, Rotaready & PayCaptain" />
              </Content>
            </Section>

            <Section>
              <PopQuote text="Flourish has had the integration between Rotaready and PayCaptain switched on since day one. The teams at Rotaready and PayCaptain worked very closely together throughout the implementation process to ensure a stress-free roll out for the team..." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="The integration between Rotaready and PayCaptain was so easy to set-up. We didn't have to do anything, it was all done in the background for us."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="Having the integration enabled means time and attendance data can be pulled directly from Rotaready into PayCaptain. This makes the whole payroll process highly automated, removes the risk of human error and ultimately ensures employees are always paid accurately and on time." />
              </Content>

              <Content>
                <Blockquote
                  text="The integration between Rotaready and PayCaptain cuts down our workload massively! The integration itself comes with heaps of benefits but the partnership between the two companies is particularly special. A three way conversation is never difficult, everyone knows the different components of what you're talking about and the support from both sides is genuinely 10/10."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>

              <Content>
                <Blockquote
                  text="It just makes sense for the company that processes your payroll to be able to talk to your rota system. I wouldn't want to work in any other way."
                  attribution="Nina Colman, Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_flourish"
                label="rotaready.com/customer-stories/hospitality-flourish"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Flourish.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/flourish/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/flourish/logo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    logoPayCaptain: file(relativePath: { eq: "customer-stories/flourish/logo-paycaptain.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 40, quality: 100)
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/flourish/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/flourish/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline3: file(relativePath: { eq: "customer-stories/flourish/inline3.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingIntegration: file(relativePath: { eq: "customer-stories/flourish/heading-seamless-integration.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingRota: file(relativePath: { eq: "customer-stories/flourish/heading-simple-smart-staff-scheduling.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    headingPayroll: file(relativePath: { eq: "customer-stories/flourish/heading-tech-savvy-payroll-partner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
